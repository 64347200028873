/*eslint-disable*/
import { veterainary, website } from "@/axios";

export default {
  getDoctorInfo() {
    return veterainary().get("doctors");
  },
  updateDoctorInfo(payload) {
    return veterainary().patch("doctors", payload);
  },
  getRequiredDocuments() {
    return veterainary().get("acceptance-docs?required_for=doctors");
  },
  uploadDotorDocument(payload) {
    return website().post("upload", payload);
  },
  updateDoctorDocuments(payload) {
    return veterainary().post("doctors/documents", payload);
  },
  getDcotorReservation() {
    return veterainary().get("doctor/reservations");
  },
  attendStatus(reservation) {
    console.log({ reservation });
    return veterainary().patch(
      `doctor/reservations/attended/${reservation.itemId}`,
      { attended: reservation.status === "attended" ? true : false }
    );
  },
  getAllCities() {
    return website().get("cities");
  },
  requestWithdraw(payload) {
    return veterainary().post("doctors/withdrawals", payload);
  },
};
